// import React, { useState } from 'react';
import thumbail from "../../src/Component/image/videosample.png";
import techcherrylogo from "../../src/Component/image/myschoolcampuslogo.png";
import React, { useEffect, useState } from "react";
import "./CSS/navbar.css";

import { Link } from "react-router-dom";
import Backend_Url from "../Config/BackendUrl";
import token from "../Config/Token";
import partener from "./image/partener.png";
import career from "./image/career.png";
import tech from "./image/tech.png";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Icon } from "@iconify/react/dist/iconify.js";
import VideoModal from "./VideoModal/VideoModal";
import { useNavigate } from 'react-router-dom';

function Navbar() {
  document.title = "TechCherry | Application Designing, Development & Services";
  const [alldata, setalldata] = useState([]);
  const [menu, setMenu] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [responsivebtn, setresponsivebtn] = useState(true);
  const [videomodal, setvideomodal] = useState(false);
  const [videourl, setvodeourl] = useState("");

  const navigate = useNavigate();

  const Product = [
    {
      id: 1,
      text: "YES-BM (My Optical App)",
      text1: "YES-BM (Stock and Billing Software)",
      text2: "YES-IM (Institute ERP)",
      text3: " YES-BM (Restaurant)",
      text4: "PARTNER WITH US",
      text5: "CAREER WITH US",
      img1: <img src={partener} alt="" />,
      img2: <img src={career} alt="" />,
    },
  ];

  const Service = [
    {
      id: 1,
      text: "Customized Mobile & Web App Development",
      text1: "Opti Commerce Development",
      text2: "Brand Creation",
      text3: "ECommerce",
      text4: "PARTNER WITH US",
      text5: "CAREER WITH US",
      img1: <img src={partener} alt="" />,
      img2: <img src={career} alt="" />,
    },
  ];

  const Techcherry = [
    {
      id: 1,
      Heading: " About Company",
      text: "Who We Are",
      text1: "What We do",
      text2: "Vision & Mission",
      text3: "Career",
      text4: "Contact Us",
      text5: "PARTNER WITH US",
      text6: "CAREER WITH US",
      img1: <img src={partener} alt="" />,
      img2: <img src={career} alt="" />,
      img3: <img src={tech} alt="" />,
    },
  ];

  const handleVideoclick = (item) => {
    setvideomodal(true);
    setvodeourl(item.url);
  };

  const tabdata = [
    {
      name: "Myopticalapp",
      subheadting: "Optical Shop Management Software",
      tagarray: [
        "Features",
        "Retail& Chain Store",
        "Lens Wholesale",
        "Frame Wholesale",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      appname: "Lens Ordering App",
    },
    {
      name: "Ophthalmology",
      subheadting: "Ophthalmology Practice system",
      tagarray: [
        "Features",
        "Ophthalmology Practice",
        "Eye Hospitals",
        "General Practice",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      appname: "",
    },
    {
      name: "YES-BM",
      subheadting: "Retails Management Software",
      tagarray: [
        "Features",
        "Cloth",
        "Hardware",
        "Footwear",
        "Electrical&Electronics",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      appname: "Supermarket & Groceries",
    },
    {
      name: "Myrestoapp",
      subheadting: "Restaurant Management system",
      tagarray: [
        "Features",
        "Table Order",
        "Home Delivery& Pickup",
        "Website & APP",
        "POS",
      ],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      appname: "Supermarket & Groceries",
    },
    {
      name: "My School Campus",
      subheadting: "Institute Management Software",
      tagarray: ["Features", "Schools", "College", "Coching Institutes"],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      appname: "",
    },
    {
      name: "Medical",
      subheadting: "",
      tagarray: ["Features", "Retails Medical Store", "Wholesale Distributers"],
      url: "https://www.youtube.com/embed/LvMYHRqZg40?si=DKhBvA-vGlTexJ7P",
      thumbail: thumbail,
      appname: "",
    },
  ];

  const Boxes = (item) => {
    return (
      <>
        <div className={`single-box-div`}>
          <div className={`single-box-top`}>
            <h3>{item.name}</h3>
            <span>{item.subheadting}</span>
          </div>

          <div className={`tag-box-div`}>
            {item.tagarray.map((item, index) => {
              return (
                <div key={index} className="tag-box">
                  <span>{item}</span>
                  <div className={`line`} />
                </div>
              );
            })}
          </div>

          {/* --------video------------- */}
          <div className={`tag-box-video`}>
            <div
              onClick={() => handleVideoclick(item)}
              style={{ backgroundImage: `url(${item.thumbail})` }}
            >
              <span>
                <Icon
                  icon="solar:play-bold"
                  width="30"
                  color="#df0e0e"
                  height="30"
                />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  console.log(alldata);
  console.log(menu);

  const fetMenuMaster = async () => {
    try {
      await axios
        .get(`${Backend_Url}/MenuMaster/getall`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        //  .then((res) => res.json())
        .then((data) => setalldata(data.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetMenuMaster();
  }, []);

  // const fetchGeneralSetting = async () => {
  //   debugger
  //   try {
  //     await axios.get(`${Backend_Url}/GeneralSetting/get/all`, {
  //       headers: {
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((res) => setMenu(res));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchGeneralSetting = async () => {
    try {
      const response = await axios.get(
        `${Backend_Url}/GeneralSetting/get/all`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data); // Log the data to inspect its structure
      setMenu(response.data);
    } catch (error) {
      console.error("Error fetching general settings:", error);
    }
  };

  useEffect(() => {
    fetchGeneralSetting();
  }, []);

  const fetchProductMaster = async () => {
    try {
      await fetch(`${Backend_Url}GeneralSetting/get/all`, {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => setMenu(res));
    } catch (error) {
      console.log(error);
    }
  };

  // ----------------------FETCHLOGON------------------------
  const fetchlogo = async () => {
    try {
      await axios
        .get(`${Backend_Url}/gallery/images/all`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
          const image = res.data.groupedImages.logo[0].Imagepath;
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchlogo();
    fetchProductMaster();
  }, []);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index); // Toggle dropdown visibility
  };

  const closedropdown = (index) => {
    setActiveDropdown(null);
  };

  const product = [
    "MyOpticalApp",
    "Ophthalmology",
    "YES-BM",
    "MyrestroApp",
    "YES-IM",
    "Medical",
  ];

  useEffect(() => {
    const windowscreensize = window.innerWidth;
    console.log(windowscreensize);
    if (windowscreensize < 600) {
      setresponsivebtn(false);
    }
  }, [window.location.pathname]);

  return (
    <>
      {/* <Helmet>
       
        <meta
          name="description"
          content="Techcherry Software Consultancy Services Pvt. Ltd. is one of the Best Application Designing and Development Company in India with vision to provide best services to its customer."
        />
        <meta
          name="keywords"
          content=" web design and development company in bhilai, web design and development company in durg, web design development company in raipur,web design and development services in india,web design and development company in bilaspur, software company in bhilai, list of software company in chhattisgarh,  top software company in chhattisgarh, application development company in chhattisgarh, software company in raipur, software company in bilashpur, software company in durg, software job in chhattisgarh, software development company in C.G, software services company in chhattisgarh"
        />
        <link rel="canonical" href="https://www.techcherry.in/" />
         
        <meta
          property="og:title"
          content="TechCherry | Application Designing, Development & Services"
        />
        <meta
          property="og:description"
          content="Techcherry Software Consultancy Services Pvt. Ltd. is one of the Best Application Designing and Development Company in India with vision to provide best services to its customer."
        />
        <meta
          name="keywords"
          content=" web design and development company in bhilai, web design and development company in durg, web design development company in raipur,web design and development services in india,web design and development company in bilaspur, software company in bhilai, list of software company in chhattisgarh,  top software company in chhattisgarh, application development company in chhattisgarh, software company in raipur, software company in bilashpur, software company in durg, software job in chhattisgarh, software development company in C.G, software services company in chhattisgarh"
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/thumbnail.jpg"
        />
        <meta property="og:url" content="https://yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      {/* <div className={`top-call-div`}>
        Call Us Demo: <span> 91-9039024004</span>
      </div> */}
      <div className="navbar bg-base-100 flex flex-col lg:flex-row lg:justify-between items-center pt-1 bg-color-header">
        <div className="navbar-center flex flex-col md:flex-row lg:justify-center w-full lg:w-auto header-logo-content">
          {menu?.map(
            (
              m,
              index //   if our data is not in manu than it is show error we used ? which chaheck the data is available or not
            ) => (
              <a
                key={index}
                href="/"
                className="logo-name btn w-auto cursor-pointer hover:!bg-transparent justify-center gap-0 btn-ghost text-xl mx-auto lg:mx-0"
              >
                <img
                  // src={`${Backend_Url.slice(0, Backend_Url.length)}${
                  //   m.SelectLogo
                  // }`}
                  src={techcherrylogo}
                  alt="Logo"
                  className="h-auto w-[40px] tech-logo-img"
                />
                {/* <span className={`p-0`}>
                  <span className={`p-0 text-red-500`}>T</span>ech
                  <span className={`p-0 text-red-500`}>C</span>herry
                </span> */}
              </a>
            )
          )}

          {/* Navigation Links */}
          <ul
            style={{ display: responsivebtn ? "flex" : "none" }}
            className="menu menu-horizontal p-0 lg:space-x-4   header-width-nav"
          >
            <li className={`responsive-btn-li`}>
              <button
                onClick={() => setresponsivebtn(!responsivebtn)}
                className={`responsive-btn-techcherry`}
              >
                <Icon icon="maki:cross" width="18" height="18" />
              </button>
            </li>
            {alldata.map((item, index) => (
              <li
                key={index}
                onMouseEnter={() => toggleDropdown(index)}
                onMouseLeave={closedropdown}
              >
                {/* <a
                  href={item.URL != "null" ? item.URL : null}
                  className="cursor-pointer header-name-size"
                >
                  {item.GroupName != null
                    ? item.Category_sub
                    : item.Category_sub}
                  { }
                </a> */}
                {item.Category_sub === "Services" ? (
                  <Link
                    to="/Services" // Replace this with the actual route for your Services page
                    className="cursor-pointer header-name-size"
                  >
                    {item.Category_sub}
                  </Link>
                ) : (
                  <a
                    href={item.URL !== "null" ? item.URL : null}
                    className="cursor-pointer header-name-size"
                  >
                    {item.Category_sub}
                  </a>
                )}
                {activeDropdown === index &&
                  (item.Category_sub === "Products" ||
                    item.Category_sub === "Service" ||
                    item.Category_sub === "Techcherry") && (
                    <ul className="p-2 bg-base-100 shadow-md home-dropdown-content">
                      {item.Category_sub === "Products" && (
                        // <li key={idx}>
                        //   <a
                        //     href="/techcherry-optical-software"
                        //     target="_blank"
                        //   >
                        //     {p.text}
                        //   </a>
                        //   <a href="/Stock-price">{p.text1}</a>
                        //   <a href="/Best-ERP-Software">{p.text2}</a>
                        //   <a href="/techcherry-restaurant" target="_blank">
                        //     {p.text3}
                        //   </a>
                        //   <div className="product-div bg-blue-900">
                        //     <div className="product1">
                        //       {p.img1}
                        //       <a href=""> {p.text4}</a>
                        //     </div>
                        //     <div className="product2">
                        //       {p.img2}
                        //       <a href="">{p.text5}</a>
                        //     </div>
                        //   </div>
                        // </li>
                        // <ul className={`product-dropdown`}>
                        // window.open
                        <>
                          <li>
                            <a
                              className={`product-dropdown`}
                              onClick={() =>
                                navigate(
                                  "/techcherry-optical-software"

                                )
                              }
                            >
                              Myopticalapp
                            </a>
                            <a
                              className={`product-dropdown`}
                              onClick={() =>
                                navigate("/ophthalmology")
                              }
                            >
                              Ophthalmology
                            </a>
                            <a
                              className={`product-dropdown`}
                              onClick={() => navigate("/")}
                            >
                              YES-BM
                            </a>
                            <a
                              className={`product-dropdown`}
                              onClick={() =>
                                navigate("/techcherry-restaurant")
                              }
                            >
                              Myrestoapp
                            </a>
                            <a
                              className={`product-dropdown`}
                              onClick={() => navigate("/education")}
                            >
                              My School Campus
                            </a>
                            <a
                              className={`product-dropdown`}
                              onClick={() => navigate("/medical")}
                            >
                              Medical
                            </a>
                          </li>
                        </>
                        // </ul>
                      )}

                      {item.Category_sub === "Services" &&
                        Service.map((s, idx) => (
                          <li key={idx}>
                            <a href="/Customized-Mobile-&-Web-App-Development">
                              {s.text}
                            </a>
                            <a href="/Opti-Commerce">{s.text1}</a>
                            <a href="/Brand-Creation">{s.text2}</a>
                            <a href="/Ecommerce">{s.text3}</a>
                            <div className="product-div bg-blue-900">
                              <div className="product1">
                                {s.img1}
                                <a href=""> {s.text4}</a>
                              </div>
                              <div className="product2">
                                {s.img2}
                                <a href="">{s.text5}</a>
                              </div>
                            </div>
                          </li>
                        ))}

                      {item.Category_sub === "Techcherry" &&
                        Techcherry.map((t, idx) => (
                          <li key={idx}>
                            {/* <h1>{t.Heading}</h1> */}
                            <Link to="/who-we-are"
                              className={`techcherry-dropdown`}
                            // href="/who-we-are"
                            >
                              {t.text}
                            </Link>
                            <Link to="/what-we-do"
                              className={`techcherry-dropdown`}
                            //href="/what-we-do"
                            >
                              {t.text1}
                            </Link>
                            <Link to="/vission-and-mission"
                              className={`techcherry-dropdown`}
                            //href="/vission-and-mission"
                            >
                              {t.text2}
                            </Link>
                            <Link to="/career" className={`techcherry-dropdown`}
                            //href="/career"
                            >
                              {t.text3}
                            </Link>
                            <Link to="/contact"
                              className={`techcherry-dropdown`}
                            //href="/contact"
                            >
                              {t.text4}
                            </Link>

                            {/* <div className="tech bg-blue-900">{t.img3}</div> */}
                            {/* <div className="product-div bg-blue-900">
                              <div className="product1">
                                {t.img1}
                                <a className={`product-text`} href="">
                                  {" "}
                                  {t.text5}
                                </a>
                              </div>
                              <div className="product2">
                                {t.img2}
                                <a className={`product-text`} href="">
                                  {t.text6}
                                </a>
                              </div>
                            </div> */}
                          </li>
                        ))}
                    </ul>
                  )}
              </li>
            ))}
          </ul>
          {/* 
          <div
            onMouseEnter={() => setActiveDropdown(3)}
            style={{ display: activeDropdown == 3 ? "flex" : "none" }}
            className={`div-for-navbar-dropdown`}
          >
            <div
              onMouseLeave={() => setActiveDropdown(null)}
              className={`div-for-box `}
            >
              {tabdata.map((item, index) => {
                return Boxes(item);
              })}
            </div>

            {videomodal ? (
              <VideoModal close={setvideomodal} videourl={videourl} />
            ) : null}

 
            <div className="div-black"></div>
          </div> */}

          {/* Button for 'Request to Quote' */}
          <div className="navbar-end lg:w-auto mt-4 lg:mt-0 flex justify-center lg:justify-end request-header-quote">
            {/* <a href="" className="btn text-blue-400 mt-[-20px]">
              Request to Quote
            </a> */}

            {/* <div className={`demo demo-desktop`}>
              Email: <p>technical.techcherry@gmail.com</p>
            </div> */}

            <div className={`demo demo-desktop`}>
              <div className="phone-icon-header"><Icon icon="fluent:call-20-filled" /></div>
              Call | Sale: <p>+91-90390 24004 | Support: 70248 24004</p>
            </div>

            {/* ---------Mobile---------- */}

            <div className={`demo-mobile`}>
              Demo: <p>91-9039024004</p>
            </div>

            <button
              onClick={() => setresponsivebtn(!responsivebtn)}
              className={`responsive-btn-techcherry`}
            >
              <Icon icon="codicon:three-bars" width="33" height="33" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
