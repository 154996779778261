import {Router,Routes,Route} from "react-router-dom"
import Home from "./Component/Home/Home";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Opticalhome from "./Optical/Home/Opticalhome";
import Reshome from "./Restorant/RESHome/Reshom";
import Resdemo from "./Restorant/Resdemo";
import Pricing from "./Component/Pricing";
import Privacy from "./Component/Privacy";
import Condition from "./Component/Condition";
import Refund from "./Component/Refund";
import Whowhere from "./Component/dropdwon/Who-we-are";
import What from "./Component/dropdwon/What";
import Mission from "./Component/dropdwon/Mission";
import Contact from "./Component/dropdwon/Contact";
import Constom from "./Component/dropdwon/Constom";
import Opticommerce from "./Component/dropdwon/Opticommerce"
import Brand from "./Component/dropdwon/Brand";
import Ecommerce from "./Component/dropdwon/Ecommerce";
import Besterp from "./Component/dropdwon/Besterp";
import Stockprice from "./Component/dropdwon/Stockprice";
import Career from "./Component/Career"
import Ophthalmology from "./Component/Ophthalmology/Ophthalmology";
import Retail from "./Component/Retail/Retail";
import Education from "./Component/Education/Education";
import Medical from "./Component/Medical/Medical";
import Services from "./Component/dropdwon/Services";
 


function App() {
  return (
    <div className="App">
   
      <Routes>
        <Route path ="/" element={<Education/>}/>
        <Route path ="/techcherry-optical-software" element={<Opticalhome/>}/>
        <Route path ="/techcherry-restaurant" element={<Reshome/>}/>
        <Route path ="/techcherry-career" element={<Career/>}/>
        
        <Route path="/career" element={<Career/>}/>
        <Route path="/price" element ={<Pricing/>}/>
        <Route path="/privacy" element ={<Privacy/>}/>
        <Route path="/condition" element ={<Condition/>}/>
        <Route path="/refund" element ={<Refund/>}/>
        <Route path="/who-we-are" element ={<Whowhere/>}/>
        <Route path="/what-we-do" element ={<What/>}/>
        <Route path="/vission-and-mission" element ={<Mission/>}/>
        <Route path="/contact" element ={<Contact/>}/>
        <Route path="/Customized-Mobile-&-Web-App-Development" element ={<Constom/>}/>
        <Route path="/Opti-Commerce" element ={<Opticommerce/>}/>
        <Route path="/Brand-Creation" element ={<Brand/>}/>
        <Route path="/Opti-Commerce" element ={<Opticommerce/>}/>
        <Route path="/Ecommerce" element ={<Ecommerce/>}/>
        <Route path="/Best-ERP-Software" element ={<Besterp/>}/>
        <Route path="/Stock-price" element ={<Stockprice/>}/>
        {/* <Route path="/ophthalmology" element={<Ophthalmology/>}/> */}
        <Route path="/retail" element={<Retail/>}/>
        {/* <Route path="/education" element={}/> */}
        {/* <Route path="/medical" element={<Medical/>}/> */}
        {/* <Route path="/Services" element={<Services/>}/> */}
     


        

      </Routes>
  
    </div>
  );
}

export default App;
